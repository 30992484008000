import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/sub/Scroll';
import React from 'react';
import logo from './assets/logo.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Home = lazy(() => import('./components/Home'));
const Ent = lazy(() => import('./components/Ent'));
const Strategic = lazy(() => import('./components/Strategic'));
const TParty = lazy(() => import('./components/TParty'));
const IT = lazy( () => import( './components/IT' ) );
const Management = lazy( () => import( './components/Management' ) );
const Contact = lazy( () => import( './components/Contact' ) );
const About = lazy(() => import('./components/About'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-[rgb(18,18,18,0.7)]">
              <LazyLoadImage
                src={logo}
                alt=""
                className="w-20 h-20 loader"
              />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route
                path="enterprise-architecture-and-IT-strategy"
                element={<Ent />}
              />
              <Route
                path="strategic-vendor-solution-selection"
                element={<Strategic />}
              />{' '}
              <Route
                path="third-party-cyber-security-risk-assurance"
                element={<TParty />}
              />
              <Route
                path="IT-assurance-and-audit"
                element={<IT />}
              />
              <Route
                path="management-system-and-IT-governance-implementation"
                element={<Management />}
              />
              <Route
                path="contact-us"
                element={<Contact />}
              />
              <Route
                path="about-us"
                element={<About />}
              />
            </Route>
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
